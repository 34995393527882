

































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import { CmsCategoryDto } from "@/api/appService";
import { createTableTree } from "@/utils/tree";

@Component({
  name: "CmsCategoryList",
})
export default class CmsCategoryList extends Vue {
  tableItems: CmsCategoryDto[] = [];
  parentId = 0;
  table = {
    listLoading: true,
  } as any;
  navDefaultList: CmsCategoryDto[] = [];

  async created() {
    await this.fetchData();
  }

  // 获取表数据
  async fetchData() {
    this.table.listLoading = true;
    await api.cmsCategory
      .getAll({ maxResultCount: 1000, isNavigation: true })
      .then((res) => {
        const tree = createTableTree(
          res.items!,
          "parentId",
          "id",
          null,
          "children"
        );

        this.tableItems = tree;
        this.table.listLoading = false;
        this.navDefaultList = [];
        for (let i = 0; i < tree.length; i++) {
          if (tree[i].categoryCode === "nav-default") {
            this.navDefaultList.push(tree[i]);
            if (tree[i].children && tree[i].children.length > 0) {
              this.ergodicTree(tree[i].children);
            }
          }
        }
        console.log("navDefaultList", this.navDefaultList);
      });
  }

  // 新建
  handleCreate() {
    this.$router.push({ name: "navigation-create", params: { parentId: "0" } });
  }

  //添加子级分类
  handleCreateChild(row: CmsCategoryDto) {
    let index = this.navDefaultList?.map((item) => item.id).indexOf(row.id);
    this.$router.push({
      name: "navigation-createChild",
      params: { parentId: row.id!.toString() },
      query: { isNavDefault: String(index !== -1) },
    });
  }

  // 编辑
  handleEdit(index: number, row: CmsCategoryDto) {
    console.log("parentId=" + row.parentId);
    let i = this.navDefaultList?.map((item) => item.id).indexOf(row.id);
    row.parentId
      ? this.$router.push({
          name: "navigation-editChild",
          params: { id: row.id!.toString() },
          query: { isNavDefault: String(i !== -1) },
        })
      : this.$router.push({
          name: "navigation-edit",
          params: { id: row.id!.toString() },
          query: { isNavDefault: String(i !== 1) },
        });
  }

  // 删除
  async handleDelete(index: number, row: CmsCategoryDto) {
    this.$confirm(
      (this as any).$l.getLanguageText("basic.disableTip").toString(),
      (this as any).$l.getLanguageText("basic.tip").toString()
    ).then(async () => {
      await api.cmsCategory.delete({ id: row.id }).then(() => {
        this.$message({
          type: "success",
          message: (this as any).$l
            .getLanguageText("basic.deleteSuccess")
            .toString(),
        });
        this.fetchData();
      });
    });
  }

  async handelOnSaved() {
    await this.fetchData();
  }

  ergodicTree(dataList: any[]) {
    for (let i = 0; i < dataList.length; i++) {
      this.navDefaultList.push(dataList[i]);
      if (dataList[i].children && dataList[i].children.length > 0) {
        this.ergodicTree(dataList[i]);
      }
    }
  }
}
